<script setup lang="ts">
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useCms } from '/~/composables/cms/use-cms'

const { cmsContent } = useCms()
</script>

<template>
  <base-mdl
    width="md"
    title-center
    header-border-bottom
    layout="v2"
    fullscreen="mobile"
    header-border
    :title="cmsContent?.paymentMethods?.aboutPayIDTitle"
  >
    <div
      class="markup mt-6"
      v-html="cmsContent?.paymentMethods?.aboutPayIDContent"
    />
  </base-mdl>
</template>
